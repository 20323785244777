import L from 'leaflet';
import React from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import Device from '../Misc/Device';

import IconUVCOn from '../assets/marker-uvc-on.png';
import IconUVCOff from '../assets/marker-uvc-off.png';
import IconUVCOnError from '../assets/marker-uvc-on-error.png';
import IconUVCOffError from '../assets/marker-uvc-off-error.png';
import IconDeviceInactive from '../assets/marker-device-inactive.png';

import { Point } from 'leaflet';
import { formatTime } from '../Misc/Helpers';
import LCSS from '../Views/LCSS';
import { Redirect } from 'react-router-dom';

const iconMarkerUVCOn = L.icon({
    iconUrl: IconUVCOn,
    iconSize: new Point(48, 48),

});

const iconMarkerUVCOff = L.icon({
    iconUrl: IconUVCOff,
    iconSize: new Point(48, 48),

});

const iconMarkerUVCOnError = L.icon({
    iconUrl: IconUVCOnError,
    iconSize: new Point(48, 48),

});

const iconMarkerUVCOffError = L.icon({
    iconUrl: IconUVCOffError,
    iconSize: new Point(48, 48),

});

const iconMarkerDeviceInactive = L.icon({
    iconUrl: IconDeviceInactive,
    iconSize: new Point(48, 48),

});

/**
 * @brief Device map marker
 */
export default class DeviceMarker extends React.Component {

    state = {
        redirectDeviceDetail: false
    }

    constructor (props) {
        super(props);
        
    }

    render () {

        /**
         * @type {Device}
         */
        let dev = this.props.device;
        if(dev == null) {
            return null;
        }

        if(this.state.redirectDeviceDetail) {

            return <Redirect push to={"./device?d=" + dev.deviceInfo.deviceid} />;
        }

        if(dev.deviceInfo.coordinates.latitude == null || dev.deviceInfo.coordinates.longitude == null)
            return null;

        let _icon = iconMarkerUVCOff;

        if(!dev.active) {
            _icon = iconMarkerDeviceInactive;
        }
        else {
            if(dev.deviceData.Error !== 0) {
                // Error icons
                if(dev.masterData.UVCOn) {
                    _icon = iconMarkerUVCOnError;
                }
                else {
                    _icon = iconMarkerUVCOffError;
                }
            }
            else {
                // No error
                if(dev.masterData.UVCOn) {
                    _icon = iconMarkerUVCOn;
                }
                else {
                    _icon = iconMarkerUVCOff;
                }
            }
        }

        
        
        return (
            <Marker 
                position={[dev.deviceInfo.coordinates.latitude, dev.deviceInfo.coordinates.longitude]} 
                icon={_icon} 
                eventHandlers={{
                    click: (e) => {
                        if(this.props.onClick !== null && this.props.onClick !== undefined)
                            this.props.onClick(dev);
                    }
                }}
                >
                <Tooltip interactive={true} direction='top' offset={[0, -10]} opacity={0.8} permanent>
                    <span onClick={() => {this.setState({redirectDeviceDetail: true})}} style={{cursor: 'pointer', color: '#03769e'}}>{dev.deviceInfo.name}</span>
                </Tooltip>
                {
                    /*
                    <Popup>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td>
                                        {
                                            LCSS.account.level >= 255 &&
                                            <a href={"./device?d=" + dev.deviceInfo.deviceid}>{dev.deviceInfo.name}</a>
                                        }
                                        {
                                            LCSS.account.level < 255 &&
                                            <span>{dev.deviceInfo.name}</span>

                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>UVC</td><td>{dev.masterData.UVCOn ? "On" : "Off"}</td>
                                </tr>
                                <tr>
                                    <td>UVC time</td><td>{formatTime(dev.masterData.UVCTime)}</td>
                                </tr>
                                <tr>
                                    <td>Box count</td><td>{dev.masterData.Boxcount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Popup>
                    */
                }
            </Marker>
        );
    }

}