import React from 'react';
import LCSS from '../Views/LCSS';
import SideBarButton from './SideBarButton';
import HomeIcon from '../assets/sidebar/home.svg';
import SystemsIcon from '../assets/sidebar/systems.svg';
import SysLogIcon from '../assets/sidebar/dns.svg';
import SettingsIcon from '../assets/sidebar/settings.svg';
import ReportIcon from '../assets/sidebar/document.svg';
import logo from '../assets/logo.svg';
import ProfileMenu from './ProfileMenu';


export default class SideBar extends React.Component {


    state = {
        displayName: "",
        organization: "",
        showProfileMenu: false,
        dropdownVisible: false,
    }
    
    constructor (props) {
        super(props);
        this.state.displayName = LCSS.account.displayName;
        this.state.organization = LCSS.account.organization;

    }



    

    render () {



        return (
            <div className="side-bar" style={{color: '#fff'}}>
                <div className="side-bar-content" style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', gap: '30px'}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}} className='logo'>

                    <img style={{width: '100%', height: "100%", maxHeight: '40px', maxWidth: "40px"}} src={logo} />

                    <div style={{flexDirection: 'column', gap: '2px'}} className='text'>
                        <span style={{fontWeight: '600', fontSize: '18px'}}>LED Future</span>
                        <span style={{fontWeight: '400', fontSize: '14px'}}>Disinfection System</span>
                    </div>

                    </div>

                    <div style={{display: 'flex', height: '100%', flexDirection: 'column', gap: "12px"}}>
                    <SideBarButton link="/">
                        <img src={HomeIcon} className="icon" />
                        <span className='icon-label'>Home</span>
                    </SideBarButton>
                    <SideBarButton link="/overview">
                        <img src={SystemsIcon} className="icon" />
                        <span className='icon-label'>UVC systems</span>
                    </SideBarButton>

                    {
                        LCSS.account.level < 127 &&
                        <SideBarButton link="/reports">
                        <img src={ReportIcon} className="icon" />
                        <span className='icon-label'>Reports</span>
                    </SideBarButton>
                    }


                    {
                        LCSS.account.level >= 127 &&
                        <>
                            <SideBarButton link="/devices">
                                <img src={SysLogIcon} className="icon" />
                                <span className='icon-label'>UVC systems log</span>
                                
                            </SideBarButton>
                            <SideBarButton link="/settings">
                                <img src={SettingsIcon} className="icon" />
                                <span className='icon-label'>Settings</span>
                            </SideBarButton>
                            <SideBarButton link="/reports">
                                <img src={ReportIcon} className="icon" />
                                <span className='icon-label'>Reports</span>
                            </SideBarButton>
                        </>
                    }
</div>

<div>

<ProfileMenu
        menuItems={[
          { label: 'Profile', action: () => window.location = "/profile" },
          { label: 'Log out', action: LCSS.instance.logOut },
        ]}
        name={this.state.displayName}
        organization={this.state.organization}
      />
   
                    </div>

                </div>
                <h3 className="copyright">
                    &copy; SoftRain Blobs Oy 2021
                </h3>
            </div>
        );
    }
}