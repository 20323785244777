import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Device, { DeviceType } from "../Misc/Device";
import { formatTime } from "../Misc/Helpers";
import LCSS from "../Views/LCSS";

export default class MapSideBar extends React.Component {

    state = {
        /**
         * @type {Device[]}
         */
        devices: [],

        redirectToDevice: false,

        redirectToDeviceID: ''
    }

    _deviceUpdateListener = -1;

    constructor (props) {

        super(props);
        
        this.onDeviceUpdate = this.onDeviceUpdate.bind(this);

        this.state.devices = Device.devices;

    }

    /**
     * @brief Called when a single or multiple devices update
     * @param {Device | null} device 
     */
    onDeviceUpdate (device) {
        this.setState({devices: Device.devices});
    }

    componentDidMount () {
        this._deviceUpdateListener = Device.addUpdateListener(this.onDeviceUpdate);
    }

    componentWillUnmount () {
        Device.removeUpdateListener(this._deviceUpdateListener);
    }

    render () {
        /**
         * @type {Device}
         */
        const dev = this.props.device;

        if(this.state.redirectToDevice) {
            return <Redirect push to={"/device?d=" + this.state.redirectToDeviceID} />
        }

        return (
            <div className="map-sidebar">
                <div style={{display: 'flex', margin: -10}}>
                    <div style={{flex: 3}}>

                    </div>
                    <div style={{flex: 1, padding: 5, fontSize: '1.5em', cursor: 'pointer', textAlign: 'end'}} onClick={() => {this.props.onClose()}}>
                        x
                    </div>

                </div>
                {
                    // Admin account has a clickable title to device details
                    LCSS.account.level >= 255 &&
                    <p className="clickable" style={{borderBottom: '1px solid #AAA', fontWeight: 'bolder'}} onClick={() => {this.setState({redirectToDevice: true, redirectToDeviceID: dev.deviceInfo.deviceid})}}>{dev.deviceInfo.name}</p>
                }
                {
                    LCSS.account.level < 255 &&
                    <p style={{borderBottom: '1px solid #AAA', fontWeight: 'bolder'}}>{dev.deviceInfo.name}</p>
                }
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Error</td><td>{dev.deviceData.Error}</td>
                            </tr>
                            <tr>
                                <td>Uptime</td><td>{formatTime(dev.masterData.Uptime)}</td>
                            </tr>
                            <tr>
                                <td>UVC time</td><td>{formatTime(dev.masterData.UVCTime)}</td>
                            </tr>
                            <tr>
                                <td>Temp LCDIO</td><td>{dev.deviceData.T1}°C</td>
                            </tr>
                            <tr>
                                <td>Temp cabin</td><td>{dev.deviceData.T2}°C</td>
                            </tr>
                            <tr>
                                <td>Fan RPM</td><td>{dev.deviceData.RPM}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}