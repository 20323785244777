import './App.css';
import React from 'react';
import LCSS from './Views/LCSS';

class App extends React.Component {

	render() {
		return (
			<div className="App">
				<LCSS></LCSS>
			</div>
		);
	}
}

export default App;
