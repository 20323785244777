import React from 'react';
import { NavLink, useLocation  } from 'react-router-dom';

/**
 * @typedef {object} props
 * @property {string} label
 * @property {string} link
 * @extends {Component<Props>}
 */
export default class SideBarButton extends React.Component {
    link = "/";

    constructor (props) {
        super(props);
        if(props.link !== null && props.link !== undefined) {
            this.link = props.link;
        }
    }

    render () {

        return (
            <NavLink to={this.link} exact={this.props.link === '/'} isActive={(match, location) => {
                // Ensure that /about is active for /about and any sub-paths
                // but not active for / (home)

                if(this.props.link === "/devices") {

                    if(location.pathname.startsWith("/devices") || location.pathname.startsWith("/device")) {
                        return true
                    }

                }


              return match && location.pathname.startsWith(this.props.link);
              }}
activeClassName="active" className="sidebar-button">
                {this.props.children}
            </NavLink>
        );
    }

}