import React from 'react';
import profileIcon from '../assets/profile-icon.png';
import LCSS from '../Views/LCSS';

import '../index.css';

/**
 * @brief Top Bar of the application
 */
export default class TopBar extends React.Component {

    state = {
        displayName: "",
        organization: "",
        showProfileMenu: false
    }
    
    constructor (props) {
        super(props);
        this.state.displayName = LCSS.account.displayName;
        this.state.organization = LCSS.account.organization;

        
        this.profileMenu = this.profileMenu.bind(this);
    }

    /**
     * @brief Profile menu
     */
    profileMenu () {
        return (
            // TODO: move to stylesheet
            <div style={{position: 'fixed', right: 10, top: 0, backgroundColor: 'white', padding: 0, paddingTop: '8vh', boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.2)', zIndex: 599}}>
                <div className='profile-menu-button' onClick={() => {window.location = "/profile"}} >
                    Profile
                </div>
                <div className='profile-menu-button' onClick={LCSS.instance.logOut} >
                    Log out
                </div>
            </div>
        );
    }

    render () {
        return (
            <div>
                <div className="top-bar" style={{verticalAlign: 'center', display: 'flex', flexDirection: 'row'}}>
                    <div style={{flex: 1}}>
                        <h1 className='title-main'>
                            LED FUTURE
                        </h1>
                        <p className='title-sub'>
                            UVC disinfection systems
                        </p>
                    </div>
                    <div className='org-title' style={{textAlign: 'center', flex: 1, margin: 'auto'}}>
                        <h3 style={{margin: 'auto', marginTop: 5}}>{this.state.organization}</h3>
                    </div>
                    <div style={{flex: 1, alignSelf: 'flex-end'}}>
                        <div 
                            style={{height: '100%', marginLeft: 'auto', padding: 10, boxSizing: 'border-box', width: 'fit-content'}} 
                            id="user-info" 
                            onClick={() => {this.setState({showProfileMenu: !this.state.showProfileMenu})}}>
                            <div style={{display: 'flex', flex: 1, flexDirection: 'row', height: '100%', alignItems: 'center', alignSelf: 'flex-end'}}>
                                <div style={{flexDirection: 'column', marginLeft: 'auto'}}>
                                    <p style={{margin: 0, padding: 0, marginRight: 5, display: 'block'}}>{this.state.displayName}</p>
                                    <p style={{margin: 0, padding: 0, fontSize: "0.5em"}}>{this.state.organization}</p>
                                </div>
                                <img style={{alignSelf: 'flex-end', height: '100%'}} alt={"profile-icon"} src={profileIcon} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showProfileMenu &&
                    this.profileMenu()
                }

            </div>
        );
    }
}