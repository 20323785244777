import React, { createRef } from "react";
import { FlexibleWidthXYPlot, HorizontalGridLines, LineSeries, XAxis, YAxis, Crosshair, RadialChart, Highlight } from "react-vis";
import { Fragment } from "react/cjs/react.production.min";
import { SelectBox } from "../Components/SelectBox";
import Device, { DeviceType } from "../Misc/Device";
import { addZ, formatDateTime, formatTime, legendItem } from "../Misc/Helpers";
import LCSS, { LogType } from "./LCSS";
import EditPen from "../assets/edit-pen.png";
import WSController, { ClientWSCommands } from "../Components/WSController";
import Select from "react-select";
import { Redirect } from "react-router-dom";


export default class DeviceDetailView extends React.Component {

    state = {
        /**
         * @type {Device}
         */
        device: null,
        /**
         * @type {Device[]}
         */
        devices: [],

        /**
         * @brief Force update
         */
        rnd: 0,

        /**
         * @type {{title: string, data: {x: Date, y: number}[]}[]}
         */
        voltHistory: [],
        /**
         * @type {{title: string, data: {x: Date, y: number}[]}[]}
         */
        ampHistory: [],
        /**
         * @type {{title: string, data: {x: Date, y: number}[]}[]}
         */
        tempHistory: [],

        fetching: true,

        historyStatusText: "Fetching history...",

        /**
         * @brief History from date
         */
        historyFrom: new Date(),
        /**
         * @brief History to date
         */
        historyTo: new Date(),

        historyFromOld: new Date(),
        historyToOld: new Date(),


        /**
         * @brief Chart crosshair data for voltages
         */
        voltCrosshairData: [],
        /**
         * @brief Chart crosshair data for amps 
         */
        ampCrosshairData: [],
        /**
         * @brief Chart crosshair data for temperatures
         */
        tempCrosshairData: [],

        showVoltsGraph: true,
        showAmpsGraph: true,
        showTempsGraph: true,

        /**
         * @brief Device logs
         * @type {{code: number, time: string, device: string}[]}
         */
        logs: [],

        editingDeviceName: false,
        deviceTmpName: "",

        organizations: [],

        deviceOrganizations: [],

        firmware: null,

        /**
         * @brief Data from Device.getUVCTime
         * @type {{dateStart: string, dateEnd: string, delta: number, uvctime: number}}
         */
        uvcOnData: null,

        redirectSystemsOverview: false,

        channelSetupData: {
            types: [0, 0, 0, 0],
            bars: [0, 0, 0, 0],
            min: [0, 0, 0, 0],
            max: [0, 0, 0, 0]
        },
        chCutoff: 0,
        limitPoints: localStorage.getItem("devdetail_graphlimit") || 500,

        highlightOpacity: 0,

        latitude: "0",
        longitude: "0"
    }
    _deviceUpdateListener = -1;

    constructor (props) {
        super(props);
        
        this.onHistoryFetched = this.onHistoryFetched.bind(this);
        this.fetchHistory = this.fetchHistory.bind(this);
        this.fetchLogs = this.fetchLogs.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);

        this.state.historyFrom.setDate(new Date().getDate() - 1);
        this.state.historyFromOld = this.state.historyFrom;


        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onNearestX = this._onNearestX.bind(this);

        this.onDeviceFound = this.onDeviceFound.bind(this);

        this.onEditNameClick = this.onEditNameClick.bind(this);
        this.onNameValidate = this.onNameValidate.bind(this);
        this.onNameEdit = this.onNameEdit.bind(this);

        
        this.saveOrganizations = this.saveOrganizations.bind(this);
        
        this.systemsInfoBox = this.systemsInfoBox.bind(this);
        this.logsBox = this.logsBox.bind(this);
        this.systemDataBox = this.systemDataBox.bind(this);
        this.uvcTimePieChart = this.uvcTimePieChart.bind(this);
        this.channelDefBox = this.channelDefBox.bind(this);

        if(LCSS.account.level < 255) {
            this.state.redirectSystemsOverview = true;
        }
    }

    /**
     * @brief Called after device is found
     */
    onDeviceFound () {
        if(this.state.device.deviceType === DeviceType.MASTER) {
            this.state.device.getOrganizations(orgs => {
                this.setState({deviceOrganizations: orgs});
            });
            this.state.showAmpsGraph = false;
            this.state.showVoltsGraph = false;
            this.setState({
                showAmpsGraph: false, 
                showVoltsGraph: false, 
                firmware: this.state.device.firmware, 
                latitude: this.state.device.deviceInfo.coordinates.latitude.toString(),
                longitude: this.state.device.deviceInfo.coordinates.longitude.toString()
            });

            this.state.device.getUVCTime(time => {
                console.log(time);
                this.setState({uvcOnData: time});
            })
        }
        if(this.state.device.deviceType === DeviceType.SLAVE) {
            this.state.device.master.getOrganizations(orgs => {
                this.setState({deviceOrganizations: orgs});
            });
            this.setState({
                channelSetupData: this.state.device.channelDefinitions, 
                chCutoff: this.state.device.master.chCutoff
            });
        }
        // Fetch history
        this.setState({fetching: true});
        this.fetchHistory();
        this.fetchLogs();
    }

    componentDidMount () {

        let url = new URL(window.location);

        let deviceid = url.searchParams.get("d");

        LCSS.fetchOrganizations((orgs) => {
            this.setState({organizations: orgs});
        });

        // Read device ID from URL  
        if(deviceid) {
            LCSS.selectedDeviceID = deviceid;
            this.state.device = Device.findDevice(LCSS.selectedDeviceID);
            if(this.state.device) {
                // Device found
                this.onDeviceFound();
                this._deviceUpdateListener = Device.addUpdateListener((d) => {
                    // Update device
                    if(d.deviceInfo.deviceid === this.state.device.deviceInfo.deviceid) {
                        this.setState({device: d, rnd: Math.random()});
                    }
                });
            }
            else {
                this._deviceUpdateListener = Device.addUpdateListener((d) => {
                    if((this.state.device === null || this.state.device === undefined) && d === null) {
                        // Receiving update for all devices
                        let dev = Device.findDevice(LCSS.selectedDeviceID);
                        this.state.device = dev;
                        this.onDeviceFound();
                        return;
                    }       
                    if((this.state.device === null || this.state.device === undefined) || (d === null || d === undefined))
                        return;
                    
                    // Update device
                    if(d.deviceInfo.deviceid === this.state.device.deviceInfo.deviceid) {
                        this.setState({device: d, rnd: Math.random()});
                    }
                });
            }
        }
    }

    componentWillUnmount () {
        Device.removeUpdateListener(this._deviceUpdateListener);
    }

    /**
     * @brief Fetches historical data
     */
    fetchHistory () {
        this.state.device.getHistory(this.state.historyFrom, this.state.historyTo, this.onHistoryFetched, this.state.limitPoints);
    }

    /**
     * @brief Fetches the logs and sets the state
     */
    fetchLogs () {
        let ndate = new Date();
        ndate.setDate(ndate.getDate() - 7);

        this.state.device.getLogs(ndate, new Date(), (logs) => {
            this.setState({logs: logs.data});
        })
    }

    /**
     * @brief Channel change event
     * @param {string} type 
     * @param {number} index 
     * @param {Event} event 
     */
    onChannelChanged (type, index, event) {
        let csd = this.state.channelSetupData;
        let val = parseInt(event.target.value);
        csd[type][index] = val === NaN ? 0 : val;
        this.setState({
            channelSetupData: csd
        })
    }

    /**
     * @brief History fetched callback
     * @param {object} data 
     */
    onHistoryFetched (data) {
        if(data.status) {
            let volts = [];
            let amps = [];
            let temps = [];

            if(this.state.device.deviceType === DeviceType.SLAVE) {
                volts.push({title: "U1", data: []});
                volts.push({title: "U2", data: []});
                volts.push({title: "U3", data: []});
                volts.push({title: "U4", data: []});
    
                amps.push({title: "I1", data: []});
                amps.push({title: "I2", data: []});
                amps.push({title: "I3", data: []});
                amps.push({title: "I4", data: []});
            }

            temps.push({title: "T1", data: []});
            temps.push({title: "T2", data: []});
            //temps.push({title: "RPM", data: []});
            if(data.data[0].data !== null) {
                for(let dp of data.data[0].data) {
                    let date = new Date(dp.time);
                    if(this.state.device.deviceType === DeviceType.SLAVE) {
                        volts[0].data.push({x: date, y: dp.data.slaveData.U1});
                        volts[1].data.push({x: date, y: dp.data.slaveData.U2});
                        volts[2].data.push({x: date, y: dp.data.slaveData.U3});
                        volts[3].data.push({x: date, y: dp.data.slaveData.U4});
    
                        amps[0].data.push({x: date, y: dp.data.slaveData.I1});
                        amps[1].data.push({x: date, y: dp.data.slaveData.I2});
                        amps[2].data.push({x: date, y: dp.data.slaveData.I3});
                        amps[3].data.push({x: date, y: dp.data.slaveData.I4});
                    }
    
                    temps[0].data.push({x: date, y: dp.data.baseData.T1});
                    temps[1].data.push({x: date, y: dp.data.baseData.T2});
                }
            }
            this.setState({fetching: false, voltHistory: volts, ampHistory: amps, tempHistory: temps});
        }
        else {
            this.setState({historyStatusText: "Failed to fetch history"});
        }
    }

    /**
     * Event handler for onMouseLeave.
     * @private
     */
    _onMouseLeave = () => {
        this.setState({voltCrosshairData: [], ampCrosshairData: [], tempCrosshairData: []});
    };

    /**
     * Event handler for onNearestX.
     * @param {Object} value Selected value.
     * @param {index} index Index of the value in the data array.
     * @private
     */
    _onNearestX = (value, {index}) => {
        let volts = this.state.voltHistory.map(d => (
            {
                x: d.data[index].x, 
                y: d.data[index].y.toFixed(2)
            }));

        let amps = this.state.ampHistory.map(d => (
            {
                x: d.data[index].x, 
                y: d.data[index].y.toFixed(0)
            }));
        let temps = this.state.tempHistory.map(d => (
            {
                x: d.data[index].x, 
                y: d.data[index].y.toFixed(2)
            }));
        this.setState({voltCrosshairData: volts, ampCrosshairData: amps, tempCrosshairData: temps});
    };

    /**
     * @brief UVC time pie chart
     */
    uvcTimePieChart () {
        if(this.state.uvcOnData === null)
            return null;

        let mobilePortrait = window.innerWidth < 768;
         
        return <div className="dyn-box" style={{flexDirection: mobilePortrait ? 'column' : 'row', display: 'flex'}}>
            <div style={{flex: 1}}>
                <RadialChart
                    data={[
                            {angle: this.state.uvcOnData.delta - this.state.uvcOnData.uvctime, color: "#416b73"}, 
                            {angle: this.state.uvcOnData.uvctime, color: "#76c7db"}
                        ]}
                    width={300}
                    height={300}
                    colorType={'literal'}
                />
            </div>
            <div style={{height: '100%', verticalAlign: 'top', flex: 1, display: 'flex', flexDirection: 'column'}}>
                <div style={{verticalAlign: 'top', flex: 3}}>
                    <h3 style={{marginBottom: 0}}>UVC on/off</h3>
                    <div>
                        <p style={{fontSize: '0.8em', marginTop: 1}}>{formatDateTime(new Date(this.state.uvcOnData.dateStart), 'fi')} - {formatDateTime(new Date(this.state.uvcOnData.dateEnd), 'fi')}</p>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Time</td><td>{formatTime(this.state.uvcOnData.delta)}</td>
                                </tr>
                                <tr>
                                    <td>UVC on</td><td>{formatTime(this.state.uvcOnData.uvctime)}</td>
                                </tr>
                                <tr>
                                    <td>UVC on %</td><td>{(this.state.uvcOnData.uvctime/this.state.uvcOnData.delta * 100).toFixed(1)}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{marginTop: 'auto', flex: 1, alignItems: 'flex-end'}}>
                {
                    new legendItem("#416b73", "UVC Off")
                }
                {
                    new legendItem("#76c7db", "UVC On")
                }
                </div>
            </div>
        </div>
    }

    /**
     * @brief Chart element for voltages
     * @returns Voltage chart
     */
    voltageChart () {
        return (<FlexibleWidthXYPlot
            height={300}
            xType="time"
            onMouseLeave={this._onMouseLeave}
            yPadding={30}
            >
            <HorizontalGridLines />
            {
                this.state.voltHistory.map((e, i) => {
                    if(i === 0) {
                        return <LineSeries
                                key={i}
                                data={e.data}
                                animation={true}
                                curve={'curveMonotoneX'}
                                onNearestX={this._onNearestX}
                                
                                />
                    }
                    else {
                        return <LineSeries
                            key={i}
                            data={e.data}
                            animation={true}
                            curve={'curveMonotoneX'}
                            />
                    }
                })
            }
            
            <XAxis
                tickFormat={d => `${addZ(d.getDate())}.${addZ(d.getMonth() + 1)} ${addZ(d.getHours())}:${addZ(d.getMinutes())}`}
            />
            <YAxis
                tickFormat={d => `${d}V`}
            />
            <Crosshair values={this.state.voltCrosshairData}>
                {
                this.state.voltCrosshairData.length > 0 &&
                <div className={"chart-crosshair"}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{borderBottom: '1px solid rgba(255, 255, 255, 0.1'}} colSpan={2}>{formatDateTime(this.state.tempCrosshairData[0].x, 'fi')}</td>
                            </tr>
                            <tr>
                                <td>U1:</td><td>{this.state.voltCrosshairData[0].y}V</td>
                            </tr>
                            <tr>
                                <td>U2:</td><td>{this.state.voltCrosshairData[1].y}V</td>
                            </tr>
                            <tr>
                                <td>U3:</td><td>{this.state.voltCrosshairData[2].y}V</td>
                            </tr>
                            <tr>
                                <td>U4:</td><td>{this.state.voltCrosshairData[3].y}V</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                }
            </Crosshair>
            <Highlight
                //drag
                opacity={this.state.highlightOpacity}
                enableX={true}
                enableY={false}
                onBrushStart={() => {
                    this.setState({
                        highlightOpacity: 0.2
                    })
                }}
                onBrushEnd={area => {
                    if(!area)
                        return;

                    this.state.historyFrom = area.left;
                    this.state.historyTo = area.right;
                    this.setState({
                        historyFrom: area.left,
                        historyTo: area.right,
                        highlightOpacity: 0
                    })
                    this.fetchHistory();
                }}
                />
        </FlexibleWidthXYPlot>)
    }

    /**
     * @brief Current chart element
     * @returns Current chart
     */
    ampChart () {
        return (<FlexibleWidthXYPlot
            height={300}
            xType="time"
            onMouseLeave={this._onMouseLeave}
            yPadding={30}
            >
            <HorizontalGridLines />
            {
                this.state.ampHistory.map((e, i) => {
                    if(i === 0) {
                        return <LineSeries
                                key={i}
                                data={e.data}
                                animation={true}
                                curve={'curveMonotoneX'}
                                onNearestX={this._onNearestX}
                                
                                />
                    }
                    else {
                        return <LineSeries
                            key={i}
                            data={e.data}
                            animation={true}
                            curve={'curveMonotoneX'}
                            />
                    }
                })
            }
            
            <XAxis
                tickFormat={d => `${addZ(d.getDate())}.${addZ(d.getMonth() + 1)} ${addZ(d.getHours())}:${addZ(d.getMinutes())}`}
            />
            <YAxis
                tickFormat={d => `${d}mA`}
                tickLabelAngle={45}
            />
            <Crosshair values={this.state.ampCrosshairData}>
                {
                this.state.ampCrosshairData.length > 0 &&
                <div className={"chart-crosshair"}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{borderBottom: '1px solid rgba(255, 255, 255, 0.1'}} colSpan={2}>{formatDateTime(this.state.tempCrosshairData[0].x, 'fi')}</td>
                            </tr>
                            <tr>
                                <td>I1:</td><td>{this.state.ampCrosshairData[0].y}mA</td>
                            </tr>
                            <tr>
                                <td>I2:</td><td>{this.state.ampCrosshairData[1].y}mA</td>
                            </tr>
                            <tr>
                                <td>I3:</td><td>{this.state.ampCrosshairData[2].y}mA</td>
                            </tr>
                            <tr>
                                <td>I4:</td><td>{this.state.ampCrosshairData[3].y}mA</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                }
            </Crosshair>
            <Highlight
                //drag
                opacity={this.state.highlightOpacity}
                enableX={true}
                enableY={false}
                onBrushStart={() => {
                    this.setState({
                        highlightOpacity: 0.2
                    })
                }}
                onBrushEnd={area => {
                    if(!area)
                        return;
                    this.state.historyFrom = area.left;
                    this.state.historyTo = area.right;
                    this.setState({
                        historyFrom: area.left,
                        historyTo: area.right,
                        highlightOpacity: 0
                    })
                    this.fetchHistory();
                }}
                />
        </FlexibleWidthXYPlot>)
    }

    /**
     * @brief Temperature & RPM char
     * @returns Temperature chart
     */
    tempChart () {
        return (<FlexibleWidthXYPlot
            height={300}
            xType="time"
            onMouseLeave={this._onMouseLeave}
            yPadding={30}
            >
            <HorizontalGridLines />
            {
                this.state.tempHistory.map((e, i) => {
                    if(i === 0) {
                        return <LineSeries
                                key={i}
                                data={e.data}
                                animation={true}
                                curve={'curveMonotoneX'}
                                onNearestX={this._onNearestX}
                                
                                />
                    }
                    else {
                        return <LineSeries
                            key={i}
                            data={e.data}
                            animation={true}
                            curve={'curveMonotoneX'}
                            />
                    }
                })
            }
            
            <XAxis
                tickFormat={d => `${addZ(d.getDate())}.${addZ(d.getMonth() + 1)} ${addZ(d.getHours())}:${addZ(d.getMinutes())}`}
            />
            <YAxis
                tickFormat={d => `${d}°C`}
            />
            <Crosshair values={this.state.tempCrosshairData}>
                {
                this.state.tempCrosshairData.length > 0 &&
                <div className={"chart-crosshair"}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{borderBottom: '1px solid rgba(255, 255, 255, 0.1'}} colSpan={2}>{formatDateTime(this.state.tempCrosshairData[0].x, 'fi')}</td>
                            </tr>
                            <tr>
                                <td>T1:</td><td>{this.state.tempCrosshairData[0].y}°C</td>
                            </tr>
                            <tr>
                                <td>T2:</td><td>{this.state.tempCrosshairData[1].y}°C</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                }
            </Crosshair>
            <Highlight
                //drag
                opacity={this.state.highlightOpacity}
                enableX={true}
                enableY={false}
                onBrushStart={() => {
                    this.setState({
                        highlightOpacity: 0.2
                    })
                }}
                onBrushEnd={area => {
                    if(!area)
                        return;
                    this.state.historyFrom = area.left;
                    this.state.historyTo = area.right;
                    this.setState({
                        historyFrom: area.left,
                        historyTo: area.right,
                        highlightOpacity: 0
                    })
                    this.fetchHistory();
                }}
                />
        </FlexibleWidthXYPlot>)
    }

    /**
     * @brief Date changed callback
     * @param {'from'|'to'} input 
     * @param {Event} e 
     */
    onDateChanged (input, e) {
        if(input === 'from') {
            this.state.historyFrom = new Date(e.target.value);
            this.setState({historyFrom: new Date(e.target.value), historyFromOld: this.state.historyFrom})
        }
        else if(input === 'to') {
            this.state.historyTo = new Date(e.target.value);
            this.setState({historyTo: new Date(e.target.value), historyToOld: this.state.historyTo})
        }

        this.fetchHistory();
    }

    /**
     * @brief Doubleclick listener for system name click
     */
    onEditNameClick () {
        this.setState({
            editingDeviceName: true, 
            deviceTmpName: this.state.device.deviceInfo.type === DeviceType.MASTER ? 
                                this.state.device.deviceInfo.name : this.state.device.master.deviceInfo.name
        });
    }

    /**
     * @brief Validates and sends the system name
     */
    onNameValidate () {

        if(this.state.deviceTmpName.length < 3) {
            // FAIL
            if(this.state.device.deviceType === DeviceType.MASTER) {
                this.state.deviceTmpName = this.state.device.deviceInfo.name;
            }
            else {
                this.state.deviceTmpName = this.state.device.master.deviceInfo.name;
            }
        }
        else {
            if(this.state.device.deviceType === DeviceType.MASTER) {
                this.state.device.deviceInfo.name = this.state.deviceTmpName;
            }
            else {
                this.state.device.master.deviceInfo.name = this.state.deviceTmpName;
            }
            
            WSController.send(JSON.stringify({
                cmd: ClientWSCommands.SETDEVICENAME,
                device: this.state.device.deviceInfo.type === DeviceType.MASTER ? 
                            this.state.device.deviceInfo.deviceid : this.state.device.master.deviceInfo.deviceid,
                name: this.state.deviceTmpName
            }));
        }

        this.setState({editingDeviceName: false});
    }
    
    /**
     * @brief System name edit callback
     * @param {Event} e 
     */
    onNameEdit (e) {
        this.setState({deviceTmpName: e.target.value});
    }

    /**
     * @brief Saves the system organizations
     */
    saveOrganizations () {
        WSController.request({
            cmd: ClientWSCommands.SETDEVORGS,
            device: this.state.device.deviceInfo.type === DeviceType.MASTER ? 
                        this.state.device.deviceInfo.deviceid : this.state.device.master.deviceInfo.deviceid,
            organizations: this.state.deviceOrganizations
        }, (data) => {

        });
    }

    /**
     * @brief Systems info box
     */
    systemsInfoBox () {
        return <div className="dyn-box">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <b>System</b>
                        </td>
                        <td>
                            {
                                !this.state.editingDeviceName &&
                                <div>
                                    <b>
                                    {
                                        this.state.device.deviceInfo.type === DeviceType.MASTER ? 
                                                this.state.device.deviceInfo.name : 
                                                this.state.device.master.deviceInfo.name
                                    }
                                    </b>
                                    <img src={EditPen} style={{cursor: 'pointer', marginLeft: 8, height: 16, verticalAlign: 'center'}} onClick={this.onEditNameClick} />   
                                </div>
                            }
                            {
                                this.state.editingDeviceName &&
                                <div>
                                    <input 
                                        type={'text'} 
                                        value={this.state.deviceTmpName} 
                                        onBlur={this.onNameValidate} 
                                        onSubmit={this.onNameValidate} 
                                        onChange={this.onNameEdit}
                                    />
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Device
                        </td>
                        <td>
                            <span>
                            {
                                // Use masters name on a slave
                                this.state.device.deviceInfo.type === DeviceType.MASTER ? 
                                    "LCDIO-A1" : 
                                    "LC-A" + this.state.device.deviceInfo.address
                            }
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Device ID
                        </td>
                        <td>
                            {this.state.device.deviceInfo.deviceid}
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>
                            System devices
                        </td>
                        <td style={{verticalAlign: 'top'}}>
                            {
                                this.state.device.deviceType === DeviceType.MASTER &&
                                <div>
                                    <b className="system-tree-field">{"LCDIO-A1"}</b>
                                    {
                                        this.state.device.slaves.map((v, i) => {
                                            return <a href={"./device?d=" + v.deviceInfo.deviceid} className="system-tree-field" key={"slave_" + i}>{"LC-A" + v.deviceInfo.address}</a>;
                                        })
                                    }
                                </div>
                            }
                            {
                                this.state.device.deviceType === DeviceType.SLAVE &&
                                <div>
                                    <a href={"./device?d=" + this.state.device.master.deviceInfo.deviceid} className="system-tree-field">{"LCDIO-A1"}</a>
                                    {
                                        this.state.device.master.slaves.map((v, i) => {
                                            if(v.deviceInfo.deviceid === this.state.device.deviceInfo.deviceid) {
                                                return <b className="system-tree-field" key={"slave_" + i}>{"LC-A" + v.deviceInfo.address}</b>;
                                            }
                                            else {
                                                return <a href={"./device?d=" + v.deviceInfo.deviceid} className="system-tree-field" key={"slave_" + i}>{"LC-A" + v.deviceInfo.address}</a>;
                                            }
                                        })
                                    }
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Organizations
                        </td>
                        <td style={{maxWidth: 200}}>
                            <Select 
                                value={this.state.deviceOrganizations.map(v => {return {value: v.id, label: v.name}})}
                                onChange={(e) => {this.setState({deviceOrganizations: e.map(v => {return {id: v.value, name: v.label}})})}}
                                options={this.state.organizations.map(v => {return {value: v.id, label: v.name}})}
                                isMulti={true}
                                onBlur={() => {this.saveOrganizations()}}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    /**
     * @brief Logs box
     */
    logsBox () {
        return <div className="dyn-box">
            <table style={{tableLayout: 'fixed'}}>
                <tbody>
                    <tr>
                        <th style={{width: '30%'}}>Date</th><th>Log</th>
                    </tr>
                </tbody>
            </table>
            <div style={{overflowY: 'scroll', maxHeight: 200}}>
                <table style={{tableLayout: 'fixed'}}>
                    <tbody>
                        {
                            this.state.logs.map((e, i) => {
                                let lc = LCSS.logCodes.find(c => c.code === e.code);
                                let cls = "default";
                                let style = {

                                }
                                if(lc.type === LogType.LOG) {
                                    style.backgroundColor = i % 2 === 0 ? '#EEE' : 'inherit';                                                
                                }
                                if(lc.type === LogType.ERROR) {
                                    cls = "error";
                                }
                                if(lc.type === LogType.WARN) {
                                    cls ="warning";
                                }
                                if(lc.code === 0) {
                                    // No errors - show as green
                                    cls = "no-error";
                                }
                                return <tr key={"log-row" + i} className={cls} style={style}>
                                    <td style={{width: '30%'}}>{formatDateTime(new Date(e.time), 'fi')}</td><td>{lc.description}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    }

    /**
     * @brief Systems data box
     */
    systemDataBox () {
        return <div className="dyn-box">
            <table>
                <tbody>
                    {
                        this.state.device.deviceType === DeviceType.MASTER &&
                        <Fragment>
                        <tr>
                            <td>System uptime</td>
                            <td>{formatTime(this.state.device.masterData.Uptime)}</td>
                        </tr>
                        <tr>
                            <td>Remote address</td>
                            <td>{this.state.device.deviceInfo.remoteAddress || '-'}</td>
                        </tr>
                        <tr>
                            <td>UVC time</td>
                            <td>{formatTime(this.state.device.masterData.UVCTime)}</td>
                        </tr>
                        <tr>
                            <td>Tray count</td>
                            <td>{this.state.device.masterData.Boxcount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0")}</td>
                        </tr>
                        <tr>
                            <td>External voltage</td>
                            <td>{this.state.device.masterData.ExtVolt} V</td>
                        </tr>
                        </Fragment>
                        
                    }
                    <tr>
                        <td>T1</td>
                        <td>{this.state.device.deviceData.T1.toFixed(1)} °C</td>
                    </tr>
                    <tr>
                        <td>T2</td>
                        <td>{this.state.device.deviceData.T2.toFixed(1)} °C</td>
                    </tr>
                    <tr>
                        <td>RPM</td>
                        <td>{this.state.device.deviceData.RPM.toFixed(0)} rpm</td>
                    </tr>
                    {
                        (this.state.device.deviceType === DeviceType.MASTER && this.state.device.checkVersion(2, 2)) &&
                        <Fragment>
                            <tr>
                                <td>WiFi RSSI</td>
                                <td>{this.state.device.masterData.rssi} dB</td>
                            </tr>
                            <tr>
                                <td>WiFi retries</td>
                                <td>{this.state.device.wificnt}</td>
                            </tr>
                        </Fragment>

                    }
                    {
                        this.state.device.deviceType === DeviceType.MASTER &&
                        <tr>
                            <td>Firmware</td>
                            <td>
                                {
                                    (this.state.device.active && !this.state.device.updating) &&
                                    <Fragment>
                                        <select value={this.state.firmware} onChange={e => {this.setState({firmware: e.target.value}); console.log(e.target.value)}}>
                                            {
                                                LCSS.firmwares.images.map((e, i) => {
                                                    if(e.device === 'LC')
                                                        return null;

                                                    let sel = false;
                                                    if(this.state.device.firmware === e.id) {
                                                        sel = true;
                                                    }
                                                    return <option key={"firmwareupload-" + i} value={e.id} selected={sel}>{e.name} ({e.major}.{e.minor})</option>
                                                })
                                                
                                            }
                                        </select> &nbsp;
                                        <button
                                            onClick={() => { 
                                                if(window.confirm("Are you sure you want to update this device?")) {
                                                    this.state.device.upgradeDevice(this.state.firmware) 
                                                }
                                            }} 
                                            disabled={false/*this.state.device.firmware === this.state.firmware*/}>
                                                Update
                                        </button>
                                        <span style={{marginLeft: 10, background: '#DDD', borderRadius: 5, padding: 7, color: '#333'}}>
                                            v{this.state.device.majorVersion}.{this.state.device.minorVersion}
                                        </span> &nbsp;
                                    </Fragment> 
                                }
                                {
                                    (!this.state.device.active && !this.state.device.updating) &&
                                    <span style={{color: '#888'}}>Device must be on to update firmware</span>
                                }
                                {
                                    this.state.device.updating &&
                                    <span style={{color: '#888'}}>Update in progress...</span>
                                }
                            </td>
                        </tr>
                    }
                    {
                        this.state.device.deviceType === DeviceType.MASTER &&
                        <tr>
                            <td>Location</td>
                            <td>
                                {
                                    (this.state.device.active && !this.state.device.updating && this.state.device.checkVersion(2, 4)) &&
                                    <Fragment>
                                        <input 
                                            value={this.state.latitude} 
                                            onChange={(e) => {
                                                this.setState({latitude: e.target.value})
                                            }}
                                            type='number' 
                                        /> , <input 
                                            value={this.state.longitude} 
                                            onChange={(e) => {
                                                this.setState({longitude: e.target.value})
                                            }}
                                            type='number' 
                                        /> &nbsp;
                                        <button 
                                            onClick={() => { 
                                                if(window.confirm("Are you sure you want to update device location?")) {
                                                    const nlat = parseFloat(this.state.latitude.replace(",", "."));
                                                    const nlng = parseFloat(this.state.longitude.replace(",", "."));

                                                    this.state.device.setLocation({lat: nlat, lng: nlng}); 
                                                }
                                            }} 
                                            disabled={false}>
                                                Set location
                                        </button> &nbsp;
                                    </Fragment>
                                }
                                {
                                    (!this.state.device.active && !this.state.device.updating) &&
                                    <span style={{color: '#888'}}>Device must be on to update location</span>
                                }
                                {
                                    (!this.state.device.checkVersion(2, 4)) &&
                                    <span style={{color: '#888'}}>Firmware version must be &gt;= 2.4</span>
                                }
                            </td>
                        </tr>
                    }
                    {
                        this.state.device.deviceType === DeviceType.MASTER &&
                        <tr>
                            <td>Reset</td>
                            <td>
                                {
                                    (this.state.device.active && 
                                        !this.state.device.updating && 
                                        this.state.device.checkVersion(2, 5)) &&
                                    <Fragment>
                                        <button 
                                            onClick={() => { 
                                                if(window.confirm("Are you sure you want to reset this device?")) {

                                                    this.state.device.resetMaster(); 
                                                }
                                            }} 
                                            disabled={false}>
                                                Reset master
                                        </button> &nbsp;
                                        <button 
                                            onClick={() => { 
                                                if(window.confirm("Are you sure you want to reset this system?")) {

                                                    this.state.device.resetSystem();
                                                }
                                            }} 
                                            disabled={false}>
                                                Reset system
                                        </button> &nbsp;
                                    </Fragment>
                                }
                                {
                                    (!this.state.device.active && !this.state.device.updating) &&
                                    <span style={{color: '#888'}}>Device must be on to reset</span>
                                }
                                {
                                    (!this.state.device.checkVersion(2, 5)) &&
                                    <span style={{color: '#888'}}>Firmware version must be &gt;= 2.5</span>
                                }
                            </td>
                        </tr>
                    }
                    {this.state.device.deviceType === DeviceType.SLAVE &&
                    <Fragment>
                        <tr>
                            <td>U1</td>
                            <td>{this.state.device.slaveData.U1.toFixed(1)} V</td>
                        </tr>
                        <tr>
                            <td>U2</td>
                            <td>{this.state.device.slaveData.U2.toFixed(1)} V</td>
                        </tr>
                        <tr>
                            <td>U3</td>
                            <td>{this.state.device.slaveData.U3.toFixed(1)} V</td>
                        </tr>
                        <tr>
                            <td>U4</td>
                            <td>{this.state.device.slaveData.U4.toFixed(1)} V</td>
                        </tr>
                        <tr>
                            <td>I1</td>
                            <td>{this.state.device.slaveData.I1.toFixed(0)} mA</td>
                        </tr>
                        <tr>
                            <td>I2</td>
                            <td>{this.state.device.slaveData.I2.toFixed(0)} mA</td>
                        </tr>
                        <tr>
                            <td>I3</td>
                            <td>{this.state.device.slaveData.I3.toFixed(0)} mA</td>
                        </tr>
                        <tr>
                            <td>I4</td>
                            <td>{this.state.device.slaveData.I4.toFixed(0)} mA</td>
                        </tr>
                    </Fragment>
                    }
                    {
                        this.state.device.deviceType === DeviceType.SLAVE &&
                        <tr>
                            <td>Reset</td>
                            <td>
                                {
                                    (this.state.device.master.active && 
                                        !this.state.device.master.updating && 
                                        this.state.device.master.checkVersion(2, 5) &&
                                        this.state.device.checkVersion(2, 1)) &&
                                    <Fragment>
                                        <button 
                                            onClick={() => { 
                                                if(window.confirm("Are you sure you want to reset this device?")) {

                                                    this.state.device.master.resetSlave(this.state.device.deviceInfo.address); 
                                                }
                                            }} 
                                            disabled={false}>
                                                Reset Slave
                                        </button>
                                    </Fragment>
                                }
                                {
                                    (!this.state.device.master.active && !this.state.device.master.updating) &&
                                    <span style={{color: '#888'}}>Device must be on to reset</span>
                                }
                                {
                                    (!this.state.device.master.checkVersion(2, 5) ||
                                    !this.state.device.checkVersion(2, 1)) &&
                                    <span style={{color: '#888'}}>Firmware version must be &gt;= 2.5 (LCDIO), &gt;= 2.1 (LC)</span>
                                }
                            </td>
                        </tr>
                    }
                    {
                        this.state.device.deviceType === DeviceType.SLAVE &&
                        <tr>
                            <td>Firmware</td>
                            <td>
                                {
                                    (this.state.device.master.active && 
                                        !this.state.device.master.updating && 
                                        this.state.device.master.checkVersion(2, 5) &&
                                        this.state.device.checkVersion(2, 1)) &&
                                    <Fragment>
                                        <select value={this.state.firmware} onChange={e => {this.setState({firmware: e.target.value}); console.log(e.target.value)}}>
                                            {
                                                LCSS.firmwares.images.map((e, i) => {
                                                    if(e.device === 'LCDIO' || e.device === undefined)
                                                        return null;
                                                        
                                                    let sel = false;
                                                    if(this.state.device.firmware === e.id) {
                                                        sel = true;
                                                    }
                                                    return <option key={"firmwareupload-" + i} value={e.id} selected={sel}>{e.name} ({e.major}.{e.minor})</option>
                                                })
                                            }
                                        </select>
                                        <button 
                                            onClick={() => { 
                                                if(window.confirm("Are you sure you want to update this device?")) {
                                                    if(this.state.firmware == null || this.state.firmware == undefined) {
                                                        let _lcFirmwares = LCSS.firmwares.images.filter(e => e.device === 'LC');
                                                        if(_lcFirmwares.length > 0) {
                                                            this.state.firmware = _lcFirmwares[0].id;
                                                            this.setState({firmware: _lcFirmwares[0].id});
                                                        }
                                                    }
                                                    this.state.device.master.updateLC(this.state.device.deviceInfo.address, this.state.firmware); 
                                                }
                                            }} 
                                            disabled={false}>
                                                Update
                                        </button>
                                        <span style={{marginLeft: 10, background: '#DDD', borderRadius: 5, padding: 7, color: '#333'}}>
                                            v{this.state.device.majorVersion}.{this.state.device.minorVersion}
                                        </span>
                                    </Fragment>
                                }
                                {
                                    (!this.state.device.master.active && !this.state.device.master.updating) &&
                                    <span style={{color: '#888'}}>Device must be on to update</span>
                                }
                                {
                                    (!this.state.device.master.checkVersion(2, 5) ||
                                        !this.state.device.checkVersion(2, 1)) &&
                                    <span style={{color: '#888'}}>Firmware version must be &gt;= 2.5 (LCDIO), &gt;= 2.1 (LC) </span>
                                }
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    }

    /**
     * @brief Channel definitions box component
     * @returns Channel definitions box
     */
    channelDefBox () {
        // Only for >=2.1 versions
        if(this.state.device.master && this.state.device.master.checkVersion(2, 1)) {
            return <div className="dyn-box">
                <table style={{tableLayout: 'fixed'}} className="channel-setups">
                    <colgroup>
                        <col span={1} style={{width: "4%"}} />
                        <col span={1} style={{width: "15%"}} />
                        <col span={1} style={{width: "27%"}} />
                        <col span={1} style={{width: "27%"}} />
                        <col span={1} style={{width: "27%"}} />

                    </colgroup>
                    <tbody>
                        <tr>
                            <th>Ch</th>
                            <th>Type</th>
                            <th>Bar count</th>
                            <th>Min current</th>
                            <th>Max current</th>
                        </tr>
                        {
                            this.state.device.channelDefinitions.bars.map((e, i) => {
                                return <tr key={"cdef-" + i}>
                                        <td>
                                            {i + 1}
                                        </td>
                                        <td>
                                            <select value={this.state.channelSetupData.types[i]} onChange={(e) => this.onChannelChanged("types", i, e)}>
                                                <option value={0}>Empty</option>
                                                <option value={1}>Light</option>
                                                <option value={2}>UVC</option>
                                                <option value={3}>Blue</option>
                                                <option value={4}>Extra</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type={'number'} value={this.state.channelSetupData.bars[i]} onChange={(e) => this.onChannelChanged("bars", i, e)} />
                                        </td>
                                        <td>
                                            <input type={'number'} value={this.state.channelSetupData.min[i]} onChange={(e) => this.onChannelChanged("min", i, e)} />
                                        </td>
                                        <td>
                                            <input type={'number'} value={this.state.channelSetupData.max[i]} onChange={(e) => this.onChannelChanged("max", i, e)} />
                                        </td>

                                    </tr>
                            })
                        }
                    </tbody>
                </table>
                {
                    // Set channel cutoff voltage
                    (this.state.device.master.majorVersion >= 2 && this.state.device.master.minorVersion >= 3) && 
                    <div>
                        <hr />
                        Channel cutoff voltage <input type={"number"} value={this.state.chCutoff} onChange={(e) => {this.setState({chCutoff: e.target.value})}} /> cV<br/>
                        <span style={{fontSize: '0.7em'}}>Channel cutoff voltage is common for every LC on the system</span>
                    </div>
                }
                <br/>
                <button 
                    style={{margin: 10}} 
                    onClick={() => this.state.device.setChannelDefs(this.state.channelSetupData, parseInt(this.state.chCutoff))}
                    disabled={!this.state.device.master.active}
                    >
                        Save
                </button>
            </div>
        }
        else {
            return <div className="dyn-box" style={{textAlign: 'center', verticalAlign: 'center'}}>
                <p style={{verticalAlign: 'center', marginTop: 25, color: 'grey'}}>
                    Channel setup only for version 2.1 and above

                </p>
            </div>
        }
    }   


    render () {

        if(this.state.redirectSystemsOverview)
            return <Redirect to="/overview" />

        if(this.state.device === null || this.state.device === undefined)
            return null; // TODO:

        let firmware = null;
        if(this.state.device.deviceInfo.type === DeviceType.MASTER) {
            firmware = LCSS.firmwares.images.find(e => this.state.device.firmware === e.id);
        }

        let mobilePortrait = window.innerWidth < 768;
        
        return (<div className="dyn-box-view">
            {
                !mobilePortrait &&
                <div style={{flexDirection: 'row', flex: 1, display: 'flex'}}>
                    <div style={{flexDirection: 'column', flex: 1, display: 'flex'}}>
                        <this.systemsInfoBox />
                        <this.logsBox />
                    </div>
                    <div style={{flexDirection: 'column', flex: 1, display: 'flex'}}>
                        <this.systemDataBox />
                    {
                        this.state.device.deviceInfo.type === DeviceType.SLAVE &&
                        <this.channelDefBox />
                    }
                    {
                        this.state.device.deviceInfo.type === DeviceType.MASTER &&
                        <this.uvcTimePieChart />
                    }
                    </div>
                </div>
            }
            {
                mobilePortrait &&
                <div style={{flexDirection: 'column', flex: 1, display: 'flex'}}>
                        <this.systemsInfoBox />
                        <this.logsBox />
                        <this.systemDataBox />
                    {
                        this.state.device.deviceInfo.type === DeviceType.SLAVE &&
                        <this.channelDefBox />
                    }
                    {
                        this.state.device.deviceInfo.type === DeviceType.MASTER &&
                        <this.uvcTimePieChart />
                    }
                </div>
            }
            <div style={{flexDirection: 'row', flex: 1, display: 'flex'}}>
                <div className="dyn-box">
                    {
                        this.state.device.deviceType === DeviceType.MASTER &&
                        <SelectBox 
                            options={[
                                { label: "Temperature", onChange: (on) => { this.setState({showTempsGraph: on}); }, selected: true }
                            ]}
                            multiSelect={true}
                        />
                    }
                    {
                        this.state.device.deviceType === DeviceType.SLAVE &&
                        <SelectBox 
                            options={[
                                { label: "Voltage", onChange: (on) => { this.setState({showVoltsGraph: on}); }, selected: true },
                                { label: "Current", onChange: (on) => { this.setState({showAmpsGraph: on}); }, selected: true },
                                { label: "Temperature", onChange: (on) => { this.setState({showTempsGraph: on}); }, selected: true }

                            ]}
                            multiSelect={true}
                        />
                    }
                    <div className="device-history-range-select">
                        from <input type="datetime-local" value={formatDateTime(this.state.historyFrom)} onChange={e => {this.onDateChanged("from", e)}} /> 
                        to <input type="datetime-local" value={formatDateTime(this.state.historyTo)} onChange={e => {this.onDateChanged("to", e)}} />
                        max points <input type="number" value={this.state.limitPoints} onChange={e => {
                            this.setState({limitPoints: parseInt(e.target.value)}); 
                            localStorage.setItem("devdetail_graphlimit", parseInt(e.target.value));
                        }} />

                    </div>
                    {this.state.fetching && 
                        <p style={{alignSelf: 'center', textAlign: 'center'}}>{this.state.historyStatusText}</p>
                    }
                    {
                        !this.state.fetching &&
                        <div onContextMenu={(ev) => {
                            // Clear highlight
                            ev.preventDefault();
                            this.state.historyFrom = this.state.historyFromOld;
                            this.state.historyTo = this.state.historyToOld;
                            this.setState({
                                historyFrom: this.state.historyFromOld,
                                historyTo: this.state.historyToOld,
                                highlightOpacity: 0
                            })
                            this.fetchHistory();
                        }}>
                        {this.state.showVoltsGraph && this.voltageChart()}
                        {this.state.showAmpsGraph && this.ampChart()}
                        {this.state.showTempsGraph && this.tempChart()}

                        </div>
                    }
                </div>
            </div>

          
        </div>);
    }
}