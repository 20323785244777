
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import profileIcon from '../assets/profile.svg';

const styles = {
  dropdownButton: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    
display: 'flex', padding: '5px'
  },
  dropdownMenu: {
    position: 'absolute',
    backgroundColor: 'white',
    fontWeight: 600,
    border: "1px solid #ccc",
    zIndex: 1000,
    width: '220px',
  },
  dropdownMenuItem: {
    padding: '10px',
    cursor: 'pointer',
  },
  dropdownMenuItemHover: {
    backgroundColor: '#f1f1f1',
  },
};

const ProfileMenu = ({ buttonText, menuItems, name, organization }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleClickOutside = event => {
    if (
      dropdownButtonRef.current &&
      !dropdownButtonRef.current.contains(event.target) &&
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };


  const updateMenuPosition = () => {
    if (dropdownButtonRef.current && dropdownMenuRef.current) {
      const buttonRect = dropdownButtonRef.current.getBoundingClientRect();
      const menuHeight = dropdownMenuRef.current.offsetHeight;

      setMenuPosition({
        top: buttonRect.top + window.scrollY - menuHeight - 10,
        left: buttonRect.left + window.scrollX,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', updateMenuPosition);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', updateMenuPosition);
    };
  }, []);


  useLayoutEffect(() => {
    if (isOpen) {
      updateMenuPosition();
    }
  }, [isOpen]);

  return (
    <div style={{ position: 'relative' }}>
    

      <div          
      ref={dropdownButtonRef}
        style={styles.dropdownButton}
        className='profile-button'
        onClick={toggleDropdown}>

                            <div style={{display: 'flex', flex: 1, flexDirection: 'row', height: '100%', width: "100%", alignItems: 'center', gap: "5px"}}>
                                <img style={{width: '100%', height: "100%", maxHeight: '40px', maxWidth: "40px"}} src={profileIcon} />
                                <div style={{flexDirection: 'column', gap: "4px"}} className='profile-info'>
                                    <span style={{textWrap: 'nowrap', lineHeight: 1, fontWeight: 500}}>{name}</span>
                                    <span style={{textWrap: 'nowrap', lineHeight: 1, fontSize: '12px', fontWeight: 400}}>{organization}</span>
                                </div>
                            </div>



                        </div>


      {isOpen &&
        ReactDOM.createPortal(
          <div
            ref={dropdownMenuRef}
            style={{
              ...styles.dropdownMenu,
              top: menuPosition.top,
              left: menuPosition.left,
            }}
          >
            {menuItems.map((item, index) => (
              <div
                key={index}
                style={styles.dropdownMenuItem}
                onMouseEnter={e => (e.currentTarget.style.backgroundColor = styles.dropdownMenuItemHover.backgroundColor)}
                onMouseLeave={e => (e.currentTarget.style.backgroundColor = 'white')}
                onClick={() => {
                  setIsOpen(false);
                  item.action();
                }}
              >
                {item.label}
              </div>
            ))}
          </div>,
          document.body
        )}
    </div>
  );
};

export default ProfileMenu;