
/**
 * @brief WebSocket communication commands
 */
export const ClientWSCommands = {
    AUTH:           0x01,
    GETDEVICES:     0x10,
    DATA:           0x11,
    NEWDEVICE:      0x12,
    GETHISTORY:     0x13,
    GETLOGS:        0x14,
    GETLOGCODES:    0x15,
    DEVEDIT:        0x16,
    ACTIVE:         0x17,
    SETDEVICENAME:  0x20,
    SETDEVORGS:     0x21,
    SETDEVNOTE:     0x22,
    SETDEVCHANNELS: 0x23,
    SETDEVLOCATION: 0x24,
    RESETSLAVE:     0x25,
    RESETMASTER:    0x26,
    RESETSYSTEM:    0x27,
    
    NEWORG:         0x40,
    NEWUSER:        0x41,
    CHANGEPASS:     0x42,
    SAVEUSERDATA:   0x43,

    GETORGS:        0x50,
    GETUSERS:       0x51,
    GETDEVORGS:     0x52,
    GETUVCTIME:     0x53,
    GETMISCINFO:    0x54,
    GETBOXCOUNT:    0x55,

    GETPHONES:      0x60,
    GETEMAILS:      0x61,

    GETFIRMWARES:   0x70,
    UPDATEDEV:      0x71,
    DEVUPDATING:    0x72,
    UPDATELC:       0x73,

    GETREPORTS:     0x74,
    GETREPORT:      0x75,
    SAVEREPORTNOTE: 0x76,
    GETREPORTDETAIL: 0x77,
    SAVEDEVICENOTE: 0x78,
    GETREPORTTRAYS: 0x79,
    GETDEVICEREPORT: 0x80,
    GETDEVICEERRORS: 0x81,
    GETREPORTSTATS: 0x82,
    GETDEVICESTATS: 0x83,
    GETDEVICEDATA: 0x84
}


/**
 * @brief Request id counter
 */
let reqid = 0;

export default class WSController {

    /**
     * @brief Instance 
     * @type {WSController}
     */
    static instance = null;

    /**
     * @brief WebSocket object
     * @type {WebSocket}
     */
    ws = null;

    /**
     * @brief onOpen callback
     * @type {Function(Event)}
     */
    onOpen = null;
    /**
     * @brief onClose callback
     * @type {Function(Event)}
     */
    onClose = null;
    /**
     * @brief onMessage callback
     * @type {Function(Event)}
     */
    onMessage = null;

    /**
     * @brief Address of the WS server
     */
    address = "wss://localhost:1234";

    /**
     * @type {{id: number, cb: function}[]}
     */
    static requests = [];

    constructor(props) {
        if(WSController.instance == null) {
            WSController.instance = this;
        }
        else {
            // Should not happen
            console.error("WSController should not be initialized twice!");
        }

        this._onopen = this._onopen.bind(this);
        this._onclose = this._onclose.bind(this);
        this._onmessage = this._onmessage.bind(this);

        if(props.onOpen !== null && props.onOpen !== undefined) {
            this.onOpen = props.onOpen;
        }
        if(props.onClose !== null && props.onClose !== undefined) {
            this.onClose = props.onClose;
        }
        if(props.onMessage !== null && props.onMessage !== undefined) {
            this.onMessage = props.onMessage;
        }

        if(props.address !== null && props.address !== undefined) {
            this.address = props.address;
        }

        this.ws = new WebSocket(this.address);
        this.ws.onopen = this._onopen;
        this.ws.onclose = this._onclose;
        this.ws.onmessage = this._onmessage;

    }

    /**
     * @brief Requests data from server
     * @param {object} message 
     * @param {function} cb 
     */
    static request (message, cb) {
        let id = reqid++;
        WSController.send(JSON.stringify({
            ...message,
            ...{reqid: id}
        }));

        WSController.requests.push({
            id: id,
            cb: cb
        });
    }

    /**
     * @brief Sends string through a WebSocket
     * @param {string} message 
     */
    static send (message) {
        if(WSController.instance.ws !== null && WSController.instance.ws.readyState === WebSocket.OPEN)
            WSController.instance.ws.send(message);
    }

    /**
     * @brief Reconnects to the WebSocket server
     */
    static reconnect () {
        WSController.instance.ws = new WebSocket(WSController.instance.address);
        WSController.instance.ws.onopen = WSController.instance._onopen;
        WSController.instance.ws.onclose = WSController.instance._onclose;
        WSController.instance.ws.onmessage = WSController.instance._onmessage;
    }

    /**
     * @brief WS OnOpen callback function
     * @param {Event} ev
     */
    _onopen (ev) {
        if(this.onOpen != null) {
            this.onOpen(ev);
        }
    }

    /**
     * @brief WS OnClose callback function
     * @param {Event} ev 
     */
    _onclose (ev) {
        if(this.onClose != null) {
            this.onClose(ev);
        }
    }

    /**
     * @brief WS OnMessage callback function
     * @param {Event} ev 
     */
    _onmessage (ev) {
        if(this.onMessage != null) {
            this.onMessage(ev);
        }

        // Handle requests
        let data = JSON.parse(ev.data);
        if(data.reqid === undefined || data.reqid === null)
            return;


        for(let i = 0; i < WSController.requests.length; i++) {
            let req = WSController.requests[i];
            if(data.reqid === req.id) {
                req.cb(data);
                WSController.requests.splice(i, 1);
                break;
            }
        }
    }
}