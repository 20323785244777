import React from 'react';

export class SelectBox extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            style: {
                ...{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 10
                },
                ...props.style
            },
            boxstyle: {
                ...{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    padding: 10,
                    backgroundColor: '#EEE',
                    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
                    borderLeft: '1px solid rgba(0,0,0,0.1)',
                    borderRight: '1px solid rgba(0,0,0,0.05)',

                },
                ...props.boxstyle
            },
            options: [],
            multiSelect: props.multiSelect || false
        }
        for(let o of this.props.options) {
            this.state.options.push({ ...o, selected: o.selected || false});
        }
        
        this.selectOption = this.selectOption.bind(this);
    }

    componentDidMount () {
        
    }

    selectOption (index) {
        let opts = this.state.options;
        if(this.state.multiSelect) {
            opts[index].selected = !opts[index].selected;
            if(opts[index].onChange) {
                opts[index].onChange(opts[index].selected);
            }
        }
        else {
            for(let i = 0; i < opts.length; i++) {
                if(i === index) {
                    opts[i].selected = true;
                    if(opts[index].onChange) {
                        opts[index].onChange(true);
                    }
                }
                else {
                    opts[i].selected = false;
                }
            }
        }
        this.setState({options: opts});
    }

    render () {
        
        return (
            <div style={this.state.style}>
                {
                    this.state.options.map((e, i) => {
                        let bstyle = { ...this.state.boxstyle };
                        if(e.selected) {
                            bstyle.backgroundColor = '#CCC';
                            bstyle.boxShadow = "none";
                        }
                        return (
                        <div className="select-box" style={bstyle} onClick={(e) => { this.selectOption(i) }} key={"select-box-" + i}>
                            <p style={{width: '100%', textAlign: 'center'}}>{e.label}</p>
                        </div>);
                    })
                }
            </div>
        )
    }
}
