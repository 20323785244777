import { Link, useParams, useHistory } from "react-router-dom/cjs/react-router-dom"
import WSController, { ClientWSCommands } from "../Components/WSController"
import { useEffect, useState } from "react"
import Device from "../Misc/Device"
import LCSS from "./LCSS"
import ReportDeviceBox from "../Components/ReportDeviceBox"
import { usePDF } from "react-to-pdf"



const getTime = (time) => {
  let timeString = "2024-01-15T14:30:00Z" // Use your actual time string

  // Create a Date object from the time string
  let date = new Date(time)

  // Specify the formatting options
  let options = { year: "numeric", month: "long" }

  // Format the date
  let formattedDate = date.toLocaleDateString("en-US", options)

  return formattedDate
}

function getYearFromDate(inputDate) {
  // Create a new Date object from the input
  const date = new Date(inputDate)

  // Check if the date is valid
  if (isNaN(date)) {
    throw new Error("Invalid date")
  }

  // Extract and return the year
  return date.getFullYear()
}

export default function ReportDeviceDetail() {
  const { reportId, deviceId } = useParams()
  const [deviceReport, setDeviceReport] = useState()
  const [devices, setDevices] = useState()
  // LCSS.selectedDeviceID = deviceId;
  const [currentDevice, setCurrentDevice] = useState(Device.findDevice(deviceId))


  const [note, setNote] = useState(deviceReport?.note)
  const [errors, setErrors] = useState()
  const [stats, setStats] = useState()
  const [definitions, setDefinitions] = useState()
  const { id } = useParams()
  const { toPDF, targetRef } = usePDF({
    filename: `${currentDevice?.deviceInfo?.name}-report-${deviceReport?.month.slice(0, 7)}.pdf`,
    method: "save",
    page: { orientation: "landscape" },
  })

  const saveNote = (content) => {
    WSController.request(
      {
        cmd: ClientWSCommands.SAVEDEVICENOTE,
        reportId: reportId,
        deviceId: deviceId,
        content: content,
      },
      ({ data }) => {}
    )
  }

  useEffect(() => {
    WSController.request(
      {
        cmd: ClientWSCommands.GETDEVICEREPORT,
        reportId: reportId,
        deviceId: deviceId,
      },
      ({ data }) => {
        setDeviceReport(data[0])
        setNote(data[0].note)
      }
    )
  }, [])

  useEffect(() => {
    if (deviceReport) {
      setCurrentDevice(Device.findDevice(deviceId))

      WSController.request(
        {
          cmd: ClientWSCommands.GETDEVICESTATS,
          device_id: deviceReport.id,
          year: getYearFromDate(deviceReport.month),
        },
        ({ data }) => {
          setStats(data)
        }
      )
    }
  }, [deviceReport])

  useEffect(() => {
    if (currentDevice && deviceReport) {
      WSController.request(
        {
          cmd: ClientWSCommands.GETDEVICEERRORS,
          month: deviceReport.month,
          devices: [currentDevice.deviceInfo.id, ...currentDevice.slaves.map((item, index) => item.deviceInfo.id)],
        },
        ({ data }) => {
          setErrors(data)
        }
      )

      WSController.request(
        {
          cmd: ClientWSCommands.GETDEVICEDATA,
          devices: { master: currentDevice.deviceInfo.id, slaves: [...currentDevice.slaves.map((item, index) => item.deviceInfo.id)] },
          month: deviceReport.month,
        },
        ({ data }) => {
          setDefinitions(data)
        }
      )
    }
  }, [currentDevice, deviceReport])

  return (
    <div style={{position: 'relative'}}>
    {deviceReport && currentDevice && errors && stats && (note || note === "") && definitions ? <>

    <button style={{position: 'absolute', right: 20, top: 20}} onClick={() => toPDF()}>Download PDF</button>
    <div ref={targetRef} style={{padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>

      
          <div style={{height: '38px', display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>{getTime(deviceReport.month)}</span>
            
          </div>

          <ReportDeviceBox
            key={"dev_box_" + currentDevice.deviceInfo.deviceid}
            device={currentDevice}
            errors={errors}
            report={deviceReport}
            stats={stats}
            note={note}
            saveNote={saveNote}
            definitions={definitions}
          />
          </div>
        </>
       : 
       <div style={{padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
           <b>Loading...</b>
       </div>
      }
    
    </div>
  )
}
