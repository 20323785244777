import React from "react";
import LCSS from "./LCSS";
import logo from '../assets/logo.svg';

export default class LoginView extends React.Component {
    
    state = {
        username: "",
        password: "",
        /** @type {string | null} */
        errorMessage: null
    }

    constructor (props) {
        super(props);

        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onLogin = this.onLogin.bind(this);

    }

    componentDidMount () {
        
    }

    componentWillUnmount () {

    }

    /**
     * @brief On username changed
     * @param {Event} ev 
     */
    onChangeUsername (ev) {
        this.setState({username: ev.target.value});
    }

    /**
     * @brief On password changed
     * @param {Event} ev 
     */
    onChangePassword (ev) {
        this.setState({password: ev.target.value});
    }

    /**
     * @brief On login
     */
    onLogin () {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password
            })
        };
        //if(LCSS.settings.loginAddress === undefined) {
            LCSS.settings.loginAddress = "https://" + window.location.host.split(":")[0] + ":4353";
            //LCSS.settings.loginAddress = "https://lcss.softrain.fi:4353";
        //}
        fetch(LCSS.settings.loginAddress, requestOptions).then(resp => resp.json()).then((data) => {
            if(data.status === true) {
                LCSS.token = data.token;
                let cookie = "login-token=" + LCSS.token + ";max-age=" + data.maxAge;
                document.cookie = cookie;
                LCSS.instance.onLogin();
            }
            else {
                // Login failed
                console.warn("Invalid username or password");
                this.setState({errorMessage: "Invalid username or password"});
            }
        })
        .catch((error) => {
            console.error("Server error while logging in", error);
            this.setState({errorMessage: "Server error while trying to log in"});
        });
    }

    render () {
        return (
            <div className="login-container">
                <div style={{maxWidth: "300px", width: "100%", display: 'flex', flexDirection: 'column', gap: "20px"}}>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center'}} className='logo'>

<img style={{width: '100%', height: "100%", maxHeight: '40px', maxWidth: "40px"}} src={logo} />

<div style={{flexDirection: 'column', gap: '2px'}} className='text'>
    <span style={{fontWeight: '600', fontSize: '18px'}}>LED Future</span>
    <span style={{fontWeight: '400', fontSize: '14px'}}>Disinfection System</span>
</div>

</div>
                    <div onKeyDown={(e) => { if(!e.repeat && e.key === 'Enter') this.onLogin()}} style={{background: '#fff', padding: "20px", display: "flex", flexDirection: 'column', gap: '20px', borderRadius: '6px'}}>

                        <span style={{fontSize: '20px', fontWeight: 700}}>Login</span>
<div style={{display: 'flex', flexDirection: 'column', gap: "15px"}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
<span style={{fontSize: '14px', fontWeight: 500}}>Username</span>
<input type="text" name="username" onChange={this.onChangeUsername} value={this.state.username} style={{width: "100%", boxSizing: 'border-box'}}/>                        
</div>

<div style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
<span style={{fontSize: '14px', fontWeight: 500}}>Password</span>
<input type="password" name="password" onChange={this.onChangePassword} value={this.state.password} style={{width: "100%", boxSizing: 'border-box'}}/>

                        </div>

                        <input type="submit" value="Log in" onClick={this.onLogin} style={{width: "100%"}}/>
        
                    </div>
                    </div>
                    {this.state.errorMessage !== null && 
                        <div>
                            <p style={{color: 'red'}}>{this.state.errorMessage}</p>
                        </div>
                    }
                    {
                        // Sometimes needed for development
                        false &&
                        <button style={{position: 'absolute', left: 0, bottom: 0}} onClick={LCSS.instance.clearSettings}>Clear settings</button>
                    }
                </div>
            </div>  
        );
    }

}