import React, { useEffect } from 'react';
import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import DeviceMarker from '../Components/DeviceMarker';
import Device, { DeviceType } from '../Misc/Device';
import MapSideBar from '../Components/MapSideBar';

import IconUVCOn from '../assets/marker-uvc-on.png';
import IconUVCOff from '../assets/marker-uvc-off.png';
import IconUVCOnError from '../assets/marker-uvc-on-error.png';
import IconUVCOffError from '../assets/marker-uvc-off-error.png';
import IconDeviceInactive from '../assets/marker-device-inactive.png';

let mapLatLngSet = false;
let mapPos = {
    latlng: [62.704566, 26.544553],
    zoom: 6 
};

function UserLocation () {

    const map = useMap();
    
    useEffect(() => {
        map.locate().on("locationfound", function (e) {
                if(!mapLatLngSet)
                    map.setView(e.latlng, 13);
            });
        map.on("moveend", function (e) {
            mapPos.latlng = e.target.getCenter();
            mapPos.zoom = e.target.getZoom();
        })
    }, [map]);

    return null;
}

function DeviceLocations () {
    const map = useMap();
    let minLat = 100;
    let minLng = 100;
    let maxLat = -100;
    let maxLng = -100;
    let coordCount = 0;

    Device.devices.forEach((d) => {
        if(d.deviceType !== DeviceType.MASTER)
            return;
        
        if(d.deviceInfo.coordinates.latitude != null && d.deviceInfo.coordinates.latitude != null) {
            if(d.deviceInfo.coordinates.latitude < minLat) {
                minLat = d.deviceInfo.coordinates.latitude;
            }
            if(d.deviceInfo.coordinates.latitude > maxLat) {
                maxLat = d.deviceInfo.coordinates.latitude;
            }
            if(d.deviceInfo.coordinates.longitude < minLng) {
                minLng = d.deviceInfo.coordinates.longitude;
            }
            if(d.deviceInfo.coordinates.longitude > maxLng) {
                maxLng = d.deviceInfo.coordinates.longitude;
            }
            coordCount++;
        }
    });
    if(!mapLatLngSet) {
        if(coordCount > 0) {
            map.fitBounds([[minLat, minLng], [maxLat, maxLng]], { padding: [50, 50] });
            mapLatLngSet = true;
        }
        console.log("Setting bounds");
    }
    return null;
}

/**
 * @brief Map legend item
 * @param {{image: string, description: string}} props
 */
const MapLegendItem = (props) => {
    return <div style={{marginLeft: 5, display: 'inline'}}>
        <img src={props.image} style={{width: 32, height: 32, display: 'inline', verticalAlign: 'middle'}} />
        <p style={{display: 'inline', verticalAlign: 'middle'}}>
            {props.description}
        </p>
    </div>
}

const legends = [
    { image: IconUVCOff, description: 'Online' },
    { image: IconDeviceInactive, description: 'Offline' },
    { image: IconUVCOn, description: 'UVC On' },
    { image: IconUVCOnError, description: 'Error' },
    { image: IconUVCOffError, description: 'Error' }
];

/**
 * @brief Map view
 */
export default class MapView extends React.Component {

    /**
     * @type {MapView}
     */
    static instance = null;

    constructor (props) {
        super(props);

        this.state = {
            /**
             * @type {Device[]}
             */
            devices: [],
            devicesFound: false,
            selectedDevice: null
        }

        this.onDeviceUpdate = this.onDeviceUpdate.bind(this);
        this.onDeviceSelected = this.onDeviceSelected.bind(this);
        this.onDeviceUpdateListener = null;

        MapView.latLngSet = false;
    }

    /**
     * 
     * @param {Device} dev 
     */
    onDeviceUpdate (dev) {
        this.setState({devices: Device.devices, devicesFound: true});
        
    }

    componentWillUnmount () {
        if(this.onDeviceUpdateListener != null) {
            Device.removeUpdateListener(this.onDeviceUpdateListener);
            this.onDeviceUpdateListener = null;
        }
        MapView.instance = null;
    }
    
    componentDidMount () {
        this.onDeviceUpdateListener = Device.addUpdateListener(this.onDeviceUpdate);
        this.onDeviceUpdate(null);
        MapView.instance = this;
    }

    onDeviceSelected (dev) {
        this.setState({selectedDevice: dev});
    }

    render () {
        return (
            <div className="mapview">
                <MapContainer center={mapPos.latlng} zoom={mapPos.zoom}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        this.state.devices.map((d) => {
                            return <DeviceMarker device={d} key={"marker-" + d.deviceInfo.deviceid} onClick={this.onDeviceSelected} />
                        })
                    }
                    <UserLocation />
                    {
                        this.state.devicesFound &&
                        <DeviceLocations />
                    }
                </MapContainer>
                {
                    this.state.selectedDevice != null &&
                    <MapSideBar device={this.state.selectedDevice} onClose={() => { this.setState({selectedDevice: null})}} />
                }
                <div className='map-legend'>
                    {
                        legends.map((e, i) => {
                            return <MapLegendItem key={"map-legend-" + i} image={e.image} description={e.description} />;
                        })
                    }
                </div>
            </div>
        );
    }

}